import { Injector, NgZone, ViewContainerRef } from '@angular/core';

import { TOKEN_KEY } from '@alan-apps/api-interfaces';
import { About } from '@alan-apps/data-access';
import { urlJoin } from '@alan-apps/utils';

export const getStorageTempPath = (...target: string[]) =>
  urlJoin('temp', ...target);

export const constants = {
  systemVersion: 2.0,
  rootData: {} as About,
  websiteTitle: {
    zhTw: '橘公寓',
  },
  url: {
    signout: ['/auth', 'signout'],
    nonAuthentication: ['/auth', 'signin'],
    confirmUser: ['/auth', 'confirm'],
  },
  tokenKey: TOKEN_KEY,
  defaultTake: 10,
  businessEntity: '經紀人(111) 高市字第01487號',
  city: {
    id: 15,
    name: '高雄市',
  },
  /**
   * take number when enable sort
   */
  sortEnableTake: 200,
  userStorage: null as unknown as LocalForage,
};
